export var rsmuNmifoEnvironment = {
  useRedirect: true,
  production: true,
  disableSso: false,
  hmr: false,
  cabinetInstanceType: 'ProfessionalEducation',
  baseRestApiUrl: 'https://stage-nmfo-spo.edu.rosminzdrav.ru/api/api',
  baseAuthUrl: 'https://stage-nmfo-spo.edu.rosminzdrav.ru/api/api/v2',
  baseWebUrl: 'https://stage-nmfo-spo.edu.rosminzdrav.ru/',
  cubaUiUrl: 'https://stage-spo.edu.rosminzdrav.ru/#',
  baseIdpUrl: 'https://stage-vo.edu.rosminzdrav.ru/idp/',
  forumUrl: '',
  supportUrl: 'http://support',
  tokenDataKey: 'tokenData',
  idpSessionIdDataKey: 'idpSessionId',
  sentryDsn: 'https://419859c338f4435c9f1b7faad788b501@sentry.edu.rosminzdrav.ru/5',
  userManualUrl: 'https://stage-nmfo-vo.edu.rosminzdrav.ru/assets/docs/user_manual.pdf',
  cubaClientId: 'stageclient',
  cubaSecret: 'stagesecret',
  reCaptchaSiteKey: '6LdoSXUUAAAAAIl-e5iVrAeQlJ8R-7XjW9fvtrLx',
  yandexCaptchaSiteKey: 'ysc1_yU3CO5wJvX4QdEkNTblA9Q6IphdNahBhxcelf55T60624ce4', 
  validateSnils: true,
  translateDictUrl: './assets/i18n/',
  imgLinksConfPath: '/assets/img/img_links.json',
  yandexMapScriptScr: 'https://api-maps.yandex.ru/2.1/?apikey=a969a63f-ad17-4aae-b590-8d25e3897959&lang=ru_RU',
  yandexMapFallbackLink: 'https://yandex.ru/maps?text=',
  yandexMetrikaConfig: {
    id: 95163581,
    clickmap:false,
    trackLinks:false,
    webvisor: false,
    accurateTrackBounce:false
  },
  disableMultizetSearch: true, // (komarov@haulmont.com) set false or delete property to enable multizet search
  linkAppleSore: null,
  linkGoogleStore: null,
  pingIntervalMs: 300000,
  notificationsPollingIntervalMs: 300000,
  dppPkCyclesOptions: {
    limit: 5,
    offsetStep: 5
  },
  orgApiUrl: 'https://stage-rest.edu.rosminzdrav.ru/es',
  mobileAppVersion: '2.1.22', 
  gitHash: '8e0dfd32'
};
Object.freeze(rsmuNmifoEnvironment);
